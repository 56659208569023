import { useCallback, useEffect, useState } from 'react';
import {
	getUserProperty,
	setUserProperties,
} from '@atlassian/jira-common-rest/src/api/latest/user-properties';
import type { AccountId } from '@atlassian/jira-shared-types/src/general.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { useRouter } from '@atlassian/react-resource-router';
import { STARRED_TAB_KEY, WORKEDON_TAB_KEY } from '../../common/constants/tab-content';
import type {
	LastVisitedTabProviderProps,
	LastVisitedTabProviderPropsFF,
} from '../../common/types/tabs';

type PrefetchPromise = Promise<string | undefined>;
export const lastVisitedUserPropertyKey = 'jira.home.last-visited-tab';
export const fetchData = async (accountId: AccountId): Promise<PrefetchPromise> => {
	try {
		const result = await getUserProperty<string>(accountId, lastVisitedUserPropertyKey);
		return result;
	} catch {
		return WORKEDON_TAB_KEY;
	}
};

export const LastVisitedTabProviderFF = ({
	prefetchResult,
	children,
}: LastVisitedTabProviderPropsFF) => {
	const { atlassianAccountId } = useTenantContext();
	const [{ route, query }, { replaceTo }] = useRouter();
	const hasQueryParamStarred = Object.keys(query).includes('starred');
	const [isStarredTabActive, setIsStarredTabActive] = useState(hasQueryParamStarred);

	const lastVisitedTab = prefetchResult.data ? prefetchResult.data.lastVisitedTab : undefined;

	const setLastVisitedTab = useCallback(
		(tabName: string) => {
			if (tabName !== STARRED_TAB_KEY) {
				replaceTo(route, { query: {} });
			}

			prefetchResult &&
				prefetchResult.data &&
				prefetchResult.data.updateLastVisitedTab &&
				prefetchResult.data.updateLastVisitedTab(tabName);

			if (!atlassianAccountId) {
				return Promise.resolve();
			}

			return setUserProperties(
				atlassianAccountId,
				lastVisitedUserPropertyKey,
				JSON.stringify(tabName),
			);
		},
		[atlassianAccountId, prefetchResult, replaceTo, route],
	);

	useEffect(() => {
		setIsStarredTabActive(Object.keys(query).includes('starred'));
	}, [query]);

	return children({
		isStarredTabActive,
		lastVisitedTab,
		setLastVisitedTab,
	});
};

const LastVisitedTabProvider = ({ prefetchResult, children }: LastVisitedTabProviderProps) => {
	const { atlassianAccountId } = useTenantContext();

	const lastVisitedTab = prefetchResult.data ? prefetchResult.data.lastVisitedTab : undefined;

	const setLastVisitedTab = useCallback(
		(tabName: string) => {
			prefetchResult &&
				prefetchResult.data &&
				prefetchResult.data.updateLastVisitedTab &&
				prefetchResult.data.updateLastVisitedTab(tabName);

			if (!atlassianAccountId) {
				return Promise.resolve();
			}

			return setUserProperties(
				atlassianAccountId,
				lastVisitedUserPropertyKey,
				JSON.stringify(tabName),
			);
		},
		[atlassianAccountId, prefetchResult],
	);

	return children({
		lastVisitedTab,
		setLastVisitedTab,
	});
};

export default LastVisitedTabProvider;
